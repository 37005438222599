
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({
  description = "Geography quizzes – Learn about the world in a fun and playful way",
  lang = "en",
  title = "Geography Games",
  cover,
  path = "/",
}) {
  const url = "https://geography.games"
  const twitterCreator = "@zcreativelabs"

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url + path} />
      <meta name="google-site-verification" content="SjCcq8lo8l3SuNrUuAbMOUMCfDmPWApb2OtE_NLF4jE" />

      { cover && <meta name="image" content={url + cover} /> }

      <meta property="og:url" content={url + path} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      { cover && <meta property="og:image" content={url + cover} /> }

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterCreator} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { cover && <meta name="twitter:image" content={url + cover} /> }
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
